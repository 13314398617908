import { useCallback, useMemo, useState } from 'react';
import { useQuery, gql, ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { DeleteIcon, ExternalLinkIcon, TimeIcon, ViewIcon, WarningIcon } from '@chakra-ui/icons';
import { Badge, Box, Button, Select, Tooltip, Container, Drawer, Tag, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Heading, HStack, Input, Link, Spacer, Spinner, Text, useColorModeValue, useDisclosure, VStack, TagLeftIcon } from '@chakra-ui/react';
import dayjs from 'dayjs'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { Services } from "../Services/Services"
import { Labels } from "../Labels/Labels"
import { FaCode, FaCodeBranch, FaPushed, FaServer, FaUserAlt } from "react-icons/fa"
import { CLUSTERS, ClusterKey } from '../../config';
import { useCluster } from '../ClusterContext/ClusterContext';

dayjs.extend(relativeTime as any)

const GET_SUBENVIRONMENTS = gql`
  query GetSubenvironments {
    subenvironments {
      id
      owner
      status {
        health
      }
      expiresAt
      labels {
        name
        value
      }
      annotations {
        name
        value
      }
      createdAt
      virtualServices {
        id
        hosts
      }
    }
  }
`;

export const Dashboard = () => {
  const [selectedSubenvironment, setSelectedSubenvironment] = useState<any>()
  const [search, setSearch] = useState<string>('')
  const { selectedCluster, setSelectedCluster } = useCluster();
  const { loading, data, error } = useQuery(GET_SUBENVIRONMENTS, {
    fetchPolicy: "no-cache",
    pollInterval: 10000,
    errorPolicy: "all",
  })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleSelectSubenvironment = (subenvironment: any) => {
    setSelectedSubenvironment(subenvironment)
    onOpen()
  }

  const filtered = useMemo(() => {
    return (data?.subenvironments || []).filter((subenvironment: any) => {
      if (search === "") return true

      if (subenvironment.id.toLowerCase().includes(search.toLowerCase())) return true
      if (subenvironment.owner.toLowerCase().includes(search.toLowerCase())) return true

      return false
    })
  }, [data, search])

  const renderError = useCallback((idx: number) => {
    const errors = error?.graphQLErrors || []

    for (const error of errors) {
      if (error?.path?.[0] === "subenvironments" && error?.path?.[1] === idx) {
        return (
          <Tooltip label={`${error?.message} [${error.path.join(".")}]`}>
            <WarningIcon w={4} h={4} color="red.300" />
          </Tooltip>
        )
      }
    }

    return null
  }, [error?.graphQLErrors])

  const fontColor = useColorModeValue('black', 'white');

  return (
    <>
      <HStack p={4} bg="gray.700" shadow="sm" color="white">
        <Heading whiteSpace="nowrap" size="lg">Subenvironments ({data?.subenvironments?.length || 0})</Heading>
        <Spacer />
        <Input w="sm" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
        <Select w="sm" value={selectedCluster} onChange={(e) => setSelectedCluster(e.target.value as ClusterKey)}>
          {Object.keys(CLUSTERS).map((cluster) => (
            <option style={{ color: fontColor }} key={cluster} value={cluster}>{cluster}</option>
          ))}
        </Select>
      </HStack>



      <Container maxW="5xl" p={4} pt={10}>
        {loading && (
          <VStack height="100vh" verticalAlign="middle">
            <Box>
              <Spinner color="gray.400" />
            </Box>
          </VStack>
        )}

        {!loading && (
          <VStack spacing={2} mt={4} align={"stretch"}>
            {filtered.map((subenvironment: any, idx: number) => (
              <HStack key={subenvironment.id} w="full" borderBottomColor={"gray.200"} borderBottomWidth={1} py={4}>
                <VStack align="stretch">
                  <HStack>
                    <Link href={`https://argocd.${CLUSTERS[selectedCluster].host}/applications?labels=subenv=${subenvironment.id}`} target="_blank">
                      <Heading size="sm">{subenvironment.id}</Heading>
                    </Link>
                    <HealthStatusTag status={subenvironment?.status?.health} />
                    <ExpiryIndicator expiresAt={subenvironment.expiresAt} />
                    {renderError(idx)}
                  </HStack>
                  <HStack>
                    <Tag size="sm" variant='ghost' borderRadius='full'>
                      <TagLeftIcon boxSize='12px' as={FaUserAlt} />
                      {subenvironment.owner || 'CI'}
                    </Tag>
                    <Tag size="sm" variant='ghost' borderRadius='full'>
                      <TagLeftIcon boxSize='12px' as={TimeIcon} />
                      {dayjs(subenvironment.createdAt).fromNow()}
                    </Tag>
                  </HStack>
                </VStack>
                <Spacer />
                <HStack>
                  {subenvironment?.labels && <Labels labels={subenvironment.labels} />}
                  {subenvironment?.virtualServices?.length > 0 && <Button colorScheme="purple" size="sm" onClick={() => handleSelectSubenvironment(subenvironment)}>Services</Button>}
                </HStack>
              </HStack>
            ))}
          </VStack>
        )}
      </Container>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{selectedSubenvironment?.id}</DrawerHeader>

          <DrawerBody>
            <Services list={selectedSubenvironment?.virtualServices} />
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

const ExpiryIndicator: React.FC<{ expiresAt: string }> = ({ expiresAt }) => {
  if (!expiresAt) {
    return null;
  }

  const expiryTime = dayjs(expiresAt);
  const now = dayjs();

  if (expiryTime.isAfter(now)) {
    // Less than 24 hours to expire
    if (expiryTime.diff(now, 'hour') <= 24) {
      return (
        <Tag size="sm" colorScheme="orange" borderRadius='full'>
          <TagLeftIcon boxSize='12px' as={TimeIcon} />
          Expires in {expiryTime.from(now)}
        </Tag>
      );
    }
  } else {
    return (
      <Tag size="sm" colorScheme="red" borderRadius='full'>
        <TagLeftIcon boxSize='12px' as={DeleteIcon} />
        Scheduled for deletion
      </Tag>
    );
  }

  return null
};

const HealthStatusTag: React.FC<{ status: string }> = ({ status }) => {
  if (!status) {
    return null;
  }

  let color
  switch (status) {
    case "progressing":
      color = "blue";
      break;
    case "degraded":
      color = "orange"
      break;
    case "suspended":
      color = "red"
      break;
    case "healthy":
      color = "green"
      break;
    default:
      color = "gray"
  }

  return (
    <Tag size="sm" borderRadius='full' colorScheme={color}>
      {status}
    </Tag>
  )
};
