import React, { createContext, useContext, useState, ReactNode } from 'react';
import { CLUSTERS, ClusterKey } from '../../config';

interface ClusterContextProps {
  selectedCluster: ClusterKey;
  setSelectedCluster: React.Dispatch<React.SetStateAction<ClusterKey>>;
}

const ClusterContext = createContext<ClusterContextProps | undefined>(undefined);

export const ClusterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedCluster, setSelectedCluster] = useState<ClusterKey>(Object.keys(CLUSTERS)[0] as ClusterKey); // default cluster

  return (
    <ClusterContext.Provider value={{ selectedCluster, setSelectedCluster }}>
      {children}
    </ClusterContext.Provider>
  );
};

export const useCluster = () => {
  const context = useContext(ClusterContext);

  if (!context) {
    throw new Error('useCluster must be used within a ClusterProvider');
  }

  return context;
};
