// A list of subenvironment clusters to connect to
export const CLUSTERS = {
  qa: {
    name: 'qa',
    host: 'qa.us-east-2.aws.dispatchfog.org'
  },
  eng: {
    name: 'eng',
    host: 'eng.us-east-2.aws.dispatchfog.org'
  },
  devops: {
    name: 'devops',
    host: 'devops.us-east-2.aws.dispatchfog.org'
  },
}

export type ClusterKey = keyof typeof CLUSTERS
