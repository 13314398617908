import { useMemo } from 'react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Button, Link } from '@chakra-ui/react';

interface Metadata {
  pullRequestID?: string
  repo?: string
}

export const Labels = ({labels}: {labels: Array<{ name: string, value: string }>}) => {
  const labelElements = useMemo(() => {
    const list = []

    const metadata: Metadata = {}

    for (const label of labels) {
      switch (label.name) {
        case 'repo':
          metadata.repo = label.value
          break
        case 'pull-request-id':
          metadata.pullRequestID = label.value
          break
      }
    }

    if (metadata.repo && metadata.pullRequestID) {
      list.push(
        <Link key={`${metadata.repo}${metadata.pullRequestID}`} href={`https://github.com/dispatchitinc/${metadata.repo}/pull/${metadata.pullRequestID}`} target="_blank" _hover={{ textDecor: 'none' }}>
          <Button variant="ghost" size="sm" rightIcon={<ExternalLinkIcon />}>
            {metadata.repo}#{metadata.pullRequestID}
          </Button>
        </Link>
      )
    }

    return list
  }, [labels])

  return (
    <>{labelElements}</>
  )
}
