import { useCallback, useMemo, useState } from 'react';
import { Button, Link, VStack } from '@chakra-ui/react';
export const Services = ({list}: {list: Array<any>}) => {
  const [search] = useState<string>('')

  const filtered = useMemo(() => {
    return (list || []).filter((vs: any) => {
      if (search === "") return true

      if (vs.id.toLowerCase().includes(search.toLowerCase())) return true

      for (const host of vs.hosts) {
        if (host.toLowerCase().includes(search.toLowerCase())) return true
      }

      return false
    })
  }, [list, search])

  const getVSName = useCallback((host: string) => {
    const parts = host.split('.')
    return parts[0]
  }, [])

  return (
    <VStack>
      {filtered.map((vs: any) => (
        <VStack key={vs.id} w="full" rounded="lg">
          {(vs?.hosts || []).map((host: string) => {
            return (
              <Link key={host} w="full" href={`https://${host}`} target="_blank" _hover={{ textDecor: 'none' }}>
                <Button w="full">{getVSName(host)}</Button>
              </Link>
            )
          })}
        </VStack>
      ))}
    </VStack>
  )
}
